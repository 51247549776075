import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ngx-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {

  @Input() addButtonText;
  @Output() addClicked = new EventEmitter();

  @Input() secondaryActionText?;
  @Input() secondaryActionIcon?;
  @Output() secondaryActionClicked = new EventEmitter();
}
