/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { WorkLog } from '../models/work-log';
import { workLogsActionsGeneratePdfReportPost } from '../fn/work-logs/work-logs-actions-generate-pdf-report-post';
import { WorkLogsActionsGeneratePdfReportPost$Params } from '../fn/work-logs/work-logs-actions-generate-pdf-report-post';
import { workLogsGet } from '../fn/work-logs/work-logs-get';
import { WorkLogsGet$Params } from '../fn/work-logs/work-logs-get';
import { workLogsPost } from '../fn/work-logs/work-logs-post';
import { WorkLogsPost$Params } from '../fn/work-logs/work-logs-post';
import { workLogsWorkLogIdDelete } from '../fn/work-logs/work-logs-work-log-id-delete';
import { WorkLogsWorkLogIdDelete$Params } from '../fn/work-logs/work-logs-work-log-id-delete';
import { workLogsWorkLogIdGet } from '../fn/work-logs/work-logs-work-log-id-get';
import { WorkLogsWorkLogIdGet$Params } from '../fn/work-logs/work-logs-work-log-id-get';
import { workLogsWorkLogIdPut } from '../fn/work-logs/work-logs-work-log-id-put';
import { WorkLogsWorkLogIdPut$Params } from '../fn/work-logs/work-logs-work-log-id-put';

@Injectable({ providedIn: 'root' })
export class WorkLogsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `workLogsGet()` */
  static readonly WorkLogsGetPath = '/work-logs';

  /**
   * Retrieve all Work Logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  workLogsGet$Response(params?: WorkLogsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WorkLog>>> {
    return workLogsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve all Work Logs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workLogsGet(params?: WorkLogsGet$Params, context?: HttpContext): Observable<Array<WorkLog>> {
    return this.workLogsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WorkLog>>): Array<WorkLog> => r.body)
    );
  }

  /** Path part for operation `workLogsPost()` */
  static readonly WorkLogsPostPath = '/work-logs';

  /**
   * Create a Work Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workLogsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workLogsPost$Response(params: WorkLogsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkLog>> {
    return workLogsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a Work Log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workLogsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workLogsPost(params: WorkLogsPost$Params, context?: HttpContext): Observable<WorkLog> {
    return this.workLogsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkLog>): WorkLog => r.body)
    );
  }

  /** Path part for operation `workLogsActionsGeneratePdfReportPost()` */
  static readonly WorkLogsActionsGeneratePdfReportPostPath = '/work-logs/actions/generate-pdf-report';

  /**
   * Generate PDF Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workLogsActionsGeneratePdfReportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workLogsActionsGeneratePdfReportPost$Response(params: WorkLogsActionsGeneratePdfReportPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return workLogsActionsGeneratePdfReportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate PDF Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workLogsActionsGeneratePdfReportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workLogsActionsGeneratePdfReportPost(params: WorkLogsActionsGeneratePdfReportPost$Params, context?: HttpContext): Observable<Blob> {
    return this.workLogsActionsGeneratePdfReportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `workLogsWorkLogIdGet()` */
  static readonly WorkLogsWorkLogIdGetPath = '/work-logs/{workLogId}';

  /**
   * Retrieve a Work Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workLogsWorkLogIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  workLogsWorkLogIdGet$Response(params: WorkLogsWorkLogIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkLog>> {
    return workLogsWorkLogIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve a Work Log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workLogsWorkLogIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workLogsWorkLogIdGet(params: WorkLogsWorkLogIdGet$Params, context?: HttpContext): Observable<WorkLog> {
    return this.workLogsWorkLogIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkLog>): WorkLog => r.body)
    );
  }

  /** Path part for operation `workLogsWorkLogIdPut()` */
  static readonly WorkLogsWorkLogIdPutPath = '/work-logs/{workLogId}';

  /**
   * Update a Work Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workLogsWorkLogIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workLogsWorkLogIdPut$Response(params: WorkLogsWorkLogIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<WorkLog>> {
    return workLogsWorkLogIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a Work Log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workLogsWorkLogIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  workLogsWorkLogIdPut(params: WorkLogsWorkLogIdPut$Params, context?: HttpContext): Observable<WorkLog> {
    return this.workLogsWorkLogIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorkLog>): WorkLog => r.body)
    );
  }

  /** Path part for operation `workLogsWorkLogIdDelete()` */
  static readonly WorkLogsWorkLogIdDeletePath = '/work-logs/{workLogId}';

  /**
   * Delete a Work Log.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `workLogsWorkLogIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  workLogsWorkLogIdDelete$Response(params: WorkLogsWorkLogIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return workLogsWorkLogIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a Work Log.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `workLogsWorkLogIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  workLogsWorkLogIdDelete(params: WorkLogsWorkLogIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.workLogsWorkLogIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
