<nb-card>
  <nb-card-body>
    <div class="row">
      <div class="col-md-12">
        <button nbButton (click)="addClicked.emit()" status="primary" size="small">
          <nb-icon icon="plus-circle-outline"/>
          {{ addButtonText }}
        </button>


        <div class="fa-pull-right">
          <button *ngIf="secondaryActionText || secondaryActionIcon" nbButton (click)="secondaryActionClicked.emit()"
                  status="control" size="small">
            <nb-icon icon="{{secondaryActionIcon}}"/>
            {{ secondaryActionText }}
          </button>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
